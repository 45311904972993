import axios from "axios";

export default {
    namespaced: true,
    state: {
        savedValues: null,
        __headers: ""
    },
    mutations: {
        setSavedValues(state, val) {
            state.savedValues = val;
        }
    },
    actions: {
        async saveCMSData(store, payload) {
            const res = await axios.post("admin/cms", payload.data, {baseURL: "/"});
            return res.data;
        },
        async loadCMSData({commit, state}, payload) {
            state.savedValues = null;
            payload = {
                fullSlug: false,
                ...payload
            }; //Default params
            let res;
            try {
                res = await axios.get("/cms", {
                    params: {...payload.queryParams},
                    ...payload.axiosParams
                });
            } catch (e) {
                return false;
            }
            let data;
            if (!payload.fullSlug) {
                data = Object.keys(res.data || {}).reduce((val, k) => {
                    let newKey;
                    if (payload.onlySlug){
                        newKey = k.split(".")[1];
                    } else {
                        newKey = k.split(".").slice(0, 2).join(".");
                    }
                    val[newKey] = res.data[k];
                    return val;
                }, {});
            } else {
                data = res.data;
            }
            if (res.headers){
                state.__headers = res.headers;
            }
            commit("setSavedValues", data);
            return data;
        },

    }
};

